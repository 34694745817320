var $globalHeader = $(".global-header");

$(function () {
	let headerHeight = $globalHeader.outerHeight();
	$("body").css({
		"--header-height": headerHeight + "px",
	});
	window.headerHeight = headerHeight;
	if (headerPositionCss === "relative") {
		$globalHeader.sticky({
			top: 0,
			zIndex: 100,
		});
	}
	$(window).on("resizeend", function (e) {
		$globalHeader.sticky("update");
	});
});

let headerPositionCss = $globalHeader.css("position");

$(window).on("scroll", function () {
	var scrollTop = window.pageYOffset || $(document).scrollTop();
	$globalHeader.toggleClass("scrolling", scrollTop > 0);
	if ($(window).width() < 1025) {
		if (scrollTop > 0) {
			$(".search-wrapper").insertBefore(".account-wrapper");
		} else {
			$(".search-wrapper").insertAfter(".header-wrapper");
		}
	}
});

// const headerAccountMapping = new MappingListener({
// 	selector: ".account-wrapper",
// 	mobileWrapper: ".menu-mobile-body",
// 	mobileMethod: "appendTo",
// 	desktopWrapper: ".cart-wrapper",
// 	desktopMethod: "insertBefore",
// 	breakpoint: 1025,
// }).watch();

const menuMapping = new MappingListener({
	selector: ".menu-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-bottom .container",
	desktopMethod: "prependTo",
	breakpoint: 1025,
}).watch();

const headerSupportMapping = new MappingListener({
	selector: ".header-support",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-right-top",
	desktopMethod: "appendTo",
	breakpoint: 1025,
}).watch();
const headerSearchMapping = new MappingListener({
	selector: ".search-wrapper",
	mobileWrapper: ".header-wrapper",
	mobileMethod: "insertAfter",
	desktopWrapper: ".header-right-bottom",
	desktopMethod: "prependTo",
	breakpoint: 1025,
}).watch();
const headerUtilMapping = new MappingListener({
	selector: ".util-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".search-wrapper",
	desktopMethod: "insertAfter",
	breakpoint: 1025,
}).watch();

const headerLanguageMapping = new MappingListener({
	selector: ".header-language",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-right-top",
	desktopMethod: "prependTo",
	breakpoint: 1025,
}).watch();

$(".close-menu-mobile, .menu-mobile-backdrop").on("click", function () {
	$(".menu-mobile").removeClass("show");
	$("body").removeClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeOut();
});

$(".toggle-sub-menu").each(function (index, el) {
	$(el).on("click", function () {
		$(el).next().toggleClass("show");
	});
});

$(".close-mega").each(function (index, el) {
	$(el).on("click", function () {
		$(el).parent().removeClass("show");
	});
});

$(".menu-toggle").on("click", function () {
	$(".menu-mobile").addClass("show");
	$("body").addClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeIn();
});

$(".close-menu-mobile, .menu-mobile-backdrop").on("click", function () {
	$(".menu-mobile").removeClass("show");
	$("body").removeClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeOut();
});

$(".search-toggle").on("click", function () {
	$(".search-wrapper").slideToggle();
});

if ($(".before-login").length > 0) {
	$(".account .dropdown-content").remove();
}
