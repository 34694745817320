function getNumberWithCommas(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

jQuery(function () {
	let minValue = $(".price-slider").attr("data-minprice")
		? $(".price-slider").attr("data-minprice")
		: 0;
	let maxValue = $(".price-slider").attr("data-maxprice");
	let textMax = $(".price-slider-max-value");
	let textMin = $(".price-slider-min-value");
	let inputMax = $("#price-slider-input-max");
	let inputMin = $("#price-slider-input-min");
	let numberMin = parseInt(minValue);
	let numberMax = parseInt(maxValue);
	console.log(numberMin, numberMax);
	function updateSliderTextAndInput(valueIndex, value) {
		var formattedValue = getNumberWithCommas(value);
		if (valueIndex === 0) {
			textMin.text(formattedValue + "₫");
			inputMin.val(formattedValue);
		} else {
			textMax.text(formattedValue + "₫");
			inputMax.val(formattedValue);
		}
		productPriceSlider.slider("values", valueIndex, parseInt(value));
	}

	var productPriceSlider = $(".price-slider").slider({
		range: true,
		min: 0,
		max: 700000000,
		step: 10000000,
		values: [numberMin, numberMax],
		slide: function (event, ui) {
			updateSliderTextAndInput(0, ui.values[0]);
			updateSliderTextAndInput(1, ui.values[1]);
		},
	});

	inputMax.on("change", function () {
		var value = Math.min(Math.max($(this).val(), minValue), maxValue);
		updateSliderTextAndInput(1, value);
	});

	inputMin.on("change", function () {
		var value = Math.min(Math.max($(this).val(), minValue), maxValue);
		updateSliderTextAndInput(0, value);
	});
});
