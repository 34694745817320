class LoopSlider {
	constructor(sliderClass, breakpoints, options = {}) {
		this.sliderClass = sliderClass;
		this.breakpoints = breakpoints;
		this.options = options;
		this.init = this.init.bind(this);
		this.init();
	}

	init() {
		$(this.sliderClass).each((index, element) => {
			const $this = $(element);
			const swiperContainer = $this.find(".swiper")[0];
			const swiperBtnPrev = $this.find(".swiper-btn-prev")[0];
			const swiperBtnNext = $this.find(".swiper-btn-next")[0];
			const swiperPagination = $this.find(".swiper-pagination")[0];
			const swiper = new Swiper(swiperContainer, {
				slidesPerView: 1,
				spaceBetween: 16,
				watchSlidesVisibility: true,
				navigation: {
					prevEl: swiperBtnPrev,
					nextEl: swiperBtnNext,
				},
				pagination: {
					el: swiperPagination,
					clickable: true,
				},
				breakpoints: this.breakpoints,
				...this.options,
			});
		});
	}
}

export default LoopSlider;
