import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";

const productDetailSlider = () => {
	const productDetailThumbnailSlider = new Swiper(
		".product-detail-thumbnail .swiper",
		{
			spaceBetween: 8,
			slidesPerView: 4,
			observer: true,
			observeParents: true,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				768: {
					slidesPerView: 5,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: 5,
					spaceBetween: 24,
					direction: "vertical",
				},
			},
		}
	);
	const productDetailImageSlider = new Swiper(
		".product-detail-images .swiper",
		{
			modules: [Navigation, Thumbs],
			observer: true,
			observeParents: true,
			thumbs: {
				swiper: productDetailThumbnailSlider,
			},
			navigation: {
				prevEl: ".product-detail-thumbnail .swiper-btn-prev",
				nextEl: ".product-detail-thumbnail .swiper-btn-next",
			},
			on: {
				slideChange: function () {
					let activeIndex = this.activeIndex + 1;

					let nextSlide = $(
						`.product-detail-thumbnail .swiper-slide:nth-child(${
							activeIndex + 1
						})`
					);
					let prevSlide = $(
						`.product-detail-thumbnail .swiper-slide:nth-child(${
							activeIndex - 1
						})`
					);

					if (
						nextSlide &&
						!nextSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slideNext();
					} else if (
						prevSlide &&
						!prevSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slidePrev();
					}
				},
			},
		}
	);
};

productDetailSlider();

$(".product-detail-label.is-zoom").on("click", function (e) {
	var images = [];
	$('[data-fancybox="product-images"]').each(function () {
		images.push({
			src: $(this).attr("href"),
			opts: {
				caption: $(this).data("caption"),
			},
		});
	});

	$.fancybox.open(images);
});

window.productDetailSlider = productDetailSlider;

if ($(".product-detail-comment").length > 0) {
	$(".product-detail-comment").append($(".commentpanel"));
}

$(".bundle-product-item-attr .tab-nav ul li a").each(function () {
	const $this = $(this);
	const id = $this.attr("href");
	const $parent = $this.parent();
	const $tabContent = $(id);
	$this.on("click", function (e) {
		e.preventDefault();
		console.log($tabContent);
		if ($parent.hasClass("active")) {
			$parent.removeClass("active");
			$tabContent.removeClass("active");
		} else {
			$parent.siblings().removeClass("active");
			$tabContent.siblings().removeClass("active");
			$parent.addClass("active");
			$tabContent.addClass("active");
		}
	});
});
