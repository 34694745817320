import scrollToElement from "./ScrollElement";

jQuery(function () {
	$(".contact-tab").tabslet({
		container: "#contactFormContainer",
	});
	$(".bundle-product-tab").tabslet({
		container: "#bundleTabContainer",
	});
	$(".product-detail-attributes .tab-nav").tabslet({});
});
