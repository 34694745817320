import { Fancybox } from "@fancyapps/ui";

var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;
window.Fancybox = Fancybox;

import "./modules/Header";
import "./modules/Slider";
import "./modules/Accordion";
import "./modules/BackToTop";
import "./modules/ExpandContent";
import "./modules/Tab";
import "./modules/Product";
import "./modules/ProductDetail";
import "./modules/PriceFilter";
import "./modules/Rating";
import "./modules/CountDown";
import "./modules/Cart";

Fancybox.bind("[data-fancybox]", {
	parentEl: document.querySelector("form"),
});
$(function () {
	$(".reveal-password").click(function () {
		// Get the password field
		var passwordField = $(".has-password-reveal input[type='password']");
		// Get the current type of the password field
		var passwordFieldType = passwordField.attr("type");

		// Check the current type and toggle it
		if (passwordFieldType == "password") {
			passwordField.attr("type", "text");
		} else {
			passwordField.attr("type", "password");
		}
		$(this).find("span").toggleClass("fa-eye fa-eye-slash");
	});
	if ($(".profile-container").length > 0) {
		$("body").addClass("background-gray-100");
	}

	$(".view-more-suggest").click(function (e) {
		e.preventDefault();
		let moreText = $(this).data("more-text");
		let lessText = $(this).data("less-text");
		$(this).toggleClass("active");
		$(this).text($(this).hasClass("active") ? lessText : moreText);
		$(".suggest-product .swiper-slide:nth-child(n+4)").slideToggle();
	});
});
